import { EmbedWrapper } from "@b2bfinance/tablo";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import { graphql, Link as GatsbyLink } from "gatsby";
import React, { useState } from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  businessSavingPageProductsContainer: {
    backgroundColor: theme.palette.grey[100]
  },
  businessSavingPageProductCount: {
    marginBottom: theme.spacing(5)
  }
}));

const BusinessSavingPage = ({ data }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const title = "Business Savings Accounts";
  const description =
    "Compare business savings account offers from the leading high street banks. Select the saving account which offers your business the best package and apply online.";
  const provider = data.allTableEmbed.nodes[0].provider;
  const products = data.allTableEmbed.nodes[0].childrenTableEmbedProduct;
  const isProductPopoverOpen = Boolean(anchorEl);
  const productPopoverId = isProductPopoverOpen
    ? "product-selection"
    : undefined;

  const handleOpenProductPopover = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseProductPopover = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography variant="h1">{title}</Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <Button
                aria-describedby={productPopoverId}
                variant="contained"
                color="default"
                giant
                onClick={handleOpenProductPopover}
              >
                Savings Accounts
                <ArrowDropDownIcon />
              </Button>
              <Popover
                id={productPopoverId}
                anchorEl={anchorEl}
                open={isProductPopoverOpen}
                onClose={handleCloseProductPopover}
              >
                <List component="nav" aria-label="secondary mailbox folders">
                  <ListItem
                    button
                    component={GatsbyLink}
                    to="/business-banking/"
                  >
                    <ListItemText primary="Current Accounts" />
                  </ListItem>
                  <ListItem
                    button
                    selected
                    component={GatsbyLink}
                    to="/business-saving/"
                  >
                    <ListItemText primary="Saving Accounts" />
                  </ListItem>
                </List>
              </Popover>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section
        gutters={[5, 0, 10]}
        className={classes.businessSavingPageProductsContainer}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.businessSavingPageProductCount}
                variant="subtitle2"
              >
                {products.length} savings accounts available
              </Typography>
            </Grid>
          </Grid>
          <EmbedWrapper provider={provider} products={products} />
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allTableEmbed(filter: { type: { eq: "BankingSavingAccount" } }) {
      nodes {
        provider
        childrenTableEmbedProduct {
          ...TableEmbedProductJSON
        }
      }
    }
  }
`;

export default BusinessSavingPage;
